import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { HomeMedicos } from '@monorepo-amais/commons/components/home'
import HomeOnly from '@monorepo-amais/commons/components/Navbar/HomeOnly'
import setMenuItens from '../components/menuList/menuList'
import { setClientMedic } from '@monorepo-amais/commons/utils/sessionControl'

import { graphql } from 'gatsby'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const IndexPage = ({ data }) => {
	setClientMedic('medico')

	return (
		<Layout isClient={false} footer={data.footer} regionais={data.regionais}>
			<SEO
				title={data.cosmicjsInformacoesEstaticas.metadata.seo.titulo}
				description={data.cosmicjsInformacoesEstaticas.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
			/>
			<HomeOnly
				urlResults={data.resultadoExame.metadata.link_medico}
				contatos={data.contatos}
				contatosAgendar={data.contatosAgendar}
				menuItens={setMenuItens(false, data.resultadoExame.metadata.link_medico)}
				isMedic
			/>
			<HomeMedicos {...data} urlResults={data.resultadoExame.metadata.link_medico} />
		</Layout>
	)
}

export const query = graphql`
	query myQuery {
		cosmicjsInformacoesEstaticas(title: { eq: "Home Medicos" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				assessoria_medica
				titulo_assessoria_medica
				titulo_resultado_de_exame
				titulo_cadastre_se
				titulo_banner
				texto_banner
				titulo_trabalhe_conosco
				resultado_de_exame_botao
				cadastre_se_botao
				trabalhe_conosco_botao
				trabalhe_conosco_botao_link
				banner {
					imgix_url
					url
				}
				banner_mobile {
					imgix_url
					url
				}
				carrossel_banner {
					carrossel_banner_imagens {
						link
						imagem {
							url
							imgix_url
						}
						imagem_mobile {
							url
							imgix_url
						}
					}
				}
				assessoria_medica
				assessoria_medica_imagem {
					imgix_url
					url
				}
				cadastre_se
				cadastre_se_imagem {
					imgix_url
					url
				}
				resultado_de_exame
				resultado_de_exame_imagem {
					imgix_url
					url
				}
				trabalhe_conosco
				trabalhe_conosco_imagem {
					imgix_url
					url
				}
			}
		}
		homeMedico: cosmicjsInformacoesEstaticas(title: { eq: "Boletim Medico" }) {
			metadata {
				titulo
				texto_destaques
				descricao
				seo {
					descricao
					imagem_descricao
					titulo
					imagem {
						imgix_url
					}
				}
			}
		}
		contatos: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
			metadata {
				titulo
				subtitulo
				canal_de_contato {
					icone {
						url
						imgix_url
					}
					titulo
					texto
				}
			}
		}
		contatosAgendar: cosmicjsInformacoesEstaticas(slug: { eq: "contatos-agendar" }) {
			metadata {
				titulo
				subtitulo
				horarios
				canal_de_contato {
					icone {
						url
						imgix_url
					}
					titulo
					texto
				}
			}
		}
		boletins: allCosmicjsBoletinsMedicos {
			nodes {
				metadata {
					edicao
					data
					ano
					destaques {
						titulo
						subtitulo
						seo {
							titulo
							descricao
							imagem {
								imgix_url
							}
						}
						conteudo
						thumbnail {
							url
							imgix_url
						}
					}
					pdf {
						url
					}
				}
			}
		}
		resultadoExame: cosmicjsInformacoesEstaticas(title: { eq: "Resultado de exame" }) {
			metadata {
				link_cliente
				link_medico
			}
		}
	}
`

export default IndexPage
